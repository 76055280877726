import Swiper from "swiper";
import { Autoplay,EffectFade,Navigation,FreeMode,A11y } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, EffectFade,Navigation,A11y]);
  export default class PushEvents extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.params = {
          period: null,
          event_category: null,
          public: null,
      };
        this.initDomElements();
        this.initEvents()
        this.initSlider();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
        this.DOM.sliderArrowNext = this.$el.querySelector('.c-push-events__arrow.next')
        this.DOM.sliderArrowPrev = this.$el.querySelector('.c-push-events__arrow.prev')
        this.DOM.selects = this.$el.querySelectorAll('select');
    }
    initEvents(){
      this.DOM.selects.forEach(select => {
        select.addEventListener('change', () => {
            const filterName = select.getAttribute('name');
            const filterValue = select.value;
            this.params[filterName] = filterValue;
            this.updateUrlParams();
        });
    });
  } 
  updateUrlParams() {
    const urlParams = new URLSearchParams();

    // Ajouter les paramètres d'URL uniquement s'ils ne sont pas null ou vides
    if (this.params.period) {
        urlParams.set('period', this.params.period);
    }
    if (this.params.event_category) {
        urlParams.set('event_category', this.params.event_category);
    }
    if (this.params.public) {
        urlParams.set('public', this.params.public);
    }

    // Mettre à jour l'URL sans recharger la page
    const newUrl = `${window.JWP.events_page}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
    window.location.href = newUrl;
}
    initSlider(){
        this.sliderObject = new Swiper(this.$el, {
            slidesPerView: 1.2,
            allowTouchMove:true,
            centeredSlides:false,
            loop:true,
            a11y:{
              enabled:true
            },
            slidesPerView: 'auto',
            navigation:{
              nextEl:this.DOM.sliderArrowNext,
              prevEl:this.DOM.sliderArrowPrev,
            },
            breakpoints:{
              '1189.98': {
                    slidesPerView: 3,
                    slidesPerView: 'auto',
                  },
            }

          });
    }
    destroy() { }
}
