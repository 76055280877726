import request from "../vendors/request";
import AbstractComponent from "./abstract-component";
import barba from "@barba/core";
export default class BookingPopin extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.action = "get_booking_popin_content"
        this.eventId = ""
        this.initDomElements()
        this.initEvents();
    }

    initDomElements() {

        this.DOM.closeBtn = this.$el.querySelector('.close')
        this.DOM.wrapper = this.$el.querySelector('.wrapper')
    }

    initEvents() {
        this.DOM.closeBtn.addEventListener('click', () => {
            this.$el.classList.remove('show')
            this.app.emit('enable-scroll')
        })
        this.app.on('open-booking-popin', (eventId) => {
            this.$el.classList.remove('full')
            this.app.gsap.set( this.DOM.error,{autoAlpha:0})
            this.DOM.wrapper.innerHTML = ''
            this.eventId = eventId
            this.$el.classList.add('loading')
            this.$el.classList.add('show')
            this.app.emit('disable-scroll')
            this.request = request.AJAX({
                'url': window.JWP.ajax_url + "?action=" + this.action,
                'data': {event_id:this.eventId},
                'success': this.onSuccess.bind(this)
            });
        })
    }
    onSuccess(response){
        this.DOM.wrapper.innerHTML = response.data.html
        this.eventSlug = response.data.slug
        this.isAvailable = response.data.is_full > 0
        this.initFormDomElements()
        if (this.isAvailable) {
            this.app.gsap.set( this.DOM.error,{display:'none'})
            this.DOM.error.innerHTML = ""
            this.initFormEvents()
            this.$el.classList.remove('full')
        }else{
            this.app.gsap.set( this.DOM.error,{display:'block'})
            this.DOM.error.innerHTML = this.DOM.error.getAttribute('data-message')
            this.app.gsap.fromTo( this.DOM.error,{x:-5,autoAlpha:0},{autoAlpha:1,x:0})
            this.$el.classList.add('full')
        }
        this.$el.classList.remove('loading')
    }
    initFormEvents(){
        this.DOM.reservationFinal.addEventListener('click', (e) => {
            e.preventDefault()
            const seats = this.DOM.seatsInput.value
            this.DOM.closeBtn.click()
            barba.force(window.JWP.reservation_page + `?event_slug=${this.eventSlug}&seats=${seats}`)

        })
    }
    initFormDomElements(){
        this.DOM.reservationFinal = this.$el.querySelector('.c-button.reservation-final')
        this.DOM.seatsInput = this.$el.querySelector('input[type="number"]')
        this.DOM.error = this.$el.querySelector('.error')
    }
    destroy() { }
}
