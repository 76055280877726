import gsap from "gsap";
import AbstractComponent from "./abstract-component";

export default class HeaderScroller extends AbstractComponent {

    constructor(el) {
        super(el)
        this.DOM = {};
        this.initDomElements();
        this.iniEvents();
    }

    initDomElements() {
    }
    iniEvents() {
        this.$el.addEventListener('click', () => {
            this.app.emit('scrollTo', this.$el)
        })
    }
    destroy() { }
}
