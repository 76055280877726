import gsap from "gsap";
import { CustomEase} from "gsap/all";
gsap.registerPlugin(CustomEase);
export default {
    previous_time:0,
    // ---------------------------------------------
    // GLOBALS
    // ---------------------------------------------
    textReveal: (way, obj) => {
        if (way === "enter") {
            // obj.el.querySelectorAll('.line').forEach((element,index) => {
            //     gsap.fromTo(element.querySelectorAll('.word'),{x:'100%'},{x:0,duration:0.8,ease: "0.14, 1.00, 0.34, 1.00",delax:index*0.4});
            // });
           
        }
    },
    timeline:(way, obj) => {
        if(window.matchMedia('(min-width:1189.98px)').matches){
            if (way === "enter") {
                gsap.to(obj.el.parentNode.querySelector('.c-timeline__item__title'),{duration:.8,x:0,autoAlpha:1,ease: "0.14, 1.00, 0.34, 1.00"})
            }else{
                gsap.to(document.querySelectorAll('.c-timeline__item__title'),{duration:.8,x:"-10%",autoAlpha:0,ease: "0.14, 1.00, 0.34, 1.00"})
            }
          }
    },
}
