import Swiper from "swiper";
import {EffectFade,Navigation,FreeMode,EffectCoverflow,A11y } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, EffectFade,Navigation,EffectCoverflow,A11y]);
  export default class SliderDouble extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initSliders();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.sliders = this.$el.querySelectorAll('.swiper')
        this.DOM.slidesOne = this.DOM.sliders[0].querySelectorAll('.swiper-slide .c-slider-double__item__title,.swiper-slide .c-slider-double__item__legend')
        this.DOM.slidersBtnNext = this.$el.querySelector('.c-slider-double__arrow.next')
        this.DOM.slidersBtnPrev = this.$el.querySelector('.c-slider-double__arrow.prev')
    }
    initSliders(){
        this.sliderMain = new Swiper(this.DOM.sliders[0], {
            allowTouchMove:true,
            slidesPerView:1.2,
            spaceBetween:26,
            initialSlide:0,
            centeredSlides:false,
            enabled:true,
            loop:true,
            a11y:{
                enabled:true
            },
            navigation:{
                nextEl:this.DOM.slidersBtnNext,
                prevEl:this.DOM.slidersBtnPrev,
              },
            breakpoints:{
                '1189.98': {
                    slidesPerView:1,
                    spaceBetween:0,
                    loop:true,
                    speed:1000,
                    centeredSlides:true,
                }
            }

          });
          this.sliderSecond = new Swiper(this.DOM.sliders[1], {
            allowTouchMove:true,
            slidesPerView:1,
            initialSlide:1,
            speed:1000,
            centeredSlides:true,
            enabled:true,
            breakpoints:{
                '1189.98': {
                    slidesPerView:1,
                    loop:true,
                    spaceBetween:0,
                    centeredSlides:true,
                }
            },
            navigation:{
                nextEl:this.DOM.slidersBtnNext,
                prevEl:this.DOM.slidersBtnPrev,
              },
          });
          if(window.matchMedia('(min-width:1189.98px)').matches){
            this.sliderMain.on('realIndexChange', (e) => {
                this.app.gsap.fromTo(this.DOM.slidesOne,{autoAlpha:1,y:0},{autoAlpha:0,y:0,duration:0.1})
                this.app.gsap.fromTo(this.DOM.slidesOne,{autoAlpha:0,y:5},{autoAlpha:1,y:0,duration:0.8},'+=0.8')
            })
        }
    }
    destroy() { }
}
