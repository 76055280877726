import gsap from "gsap";

export default {
    images: (data) => {
        let imgs = data.el.querySelectorAll('.c-slider_image__images .swiper-slide');
        let progress = data.progress;
        let maxTranslate = window.innerWidth;
        if (window.matchMedia('(min-width:1189.98px)').matches) {
            if (progress >= 0 && progress <= 1) {
                imgs.forEach(img => {
                    let imgRect = img.getBoundingClientRect();
                    let imgCenterX = imgRect.left + imgRect.width / 2;
                    let scaleProgress = (imgCenterX / (window.innerWidth / 2))

                    if (imgCenterX < window.innerWidth / 2) {
                        scaleProgress = (imgCenterX / window.innerWidth)
                    } else {

                        scaleProgress = ((imgCenterX - window.innerWidth) * -1 / (window.innerWidth))
                    }
                    
                    scaleProgress = scaleProgress * 2

                    let scaleFactor = scaleProgress > 1 ? 1 : scaleProgress < 0 ? 0 : scaleProgress;
                    gsap.set(img, { x: maxTranslate * progress * -1, scale: Math.pow(scaleFactor, 0.3)});
                });
            }
        }
    },
};
