import gsap from "gsap";

export default class SiteMenu {

    constructor(app) {
        this.className = 'c-site-menu'
        this.tl = null
        this.app = app
        this.DOM = {};
        this.initDomElements();
        this.iniEvents();
    }

    initDomElements() {
        this.$el = document.querySelector('.' + this.className)
        this.DOM = {}
        this.DOM.burger = this.$el.querySelector('.' + this.className + '__burger')
        this.DOM.navMobile = this.$el.querySelector('.' + this.className + '__nav-list')
        this.DOM.links = this.$el.querySelectorAll('.' + this.className + '__nav-list >li')
        this.DOM.submenus = this.$el.querySelectorAll('.' + this.className + '__nav-list__item-dropdown')
    }
    closeMenu(){
        if(window.matchMedia('(min-width:1189.98px)').matches){
            document.querySelectorAll('a').forEach(item => {
                item.blur()
            });
        }else{
            if (this.DOM.burger.classList.contains('active')) {
                this.DOM.burger.click();
            }
        }
    }
    iniEvents(){
        this.app.on('close-menu',()=>{
            this.closeMenu()
        })
        this.DOM.burger.addEventListener('click',()=>{
            this.tl = this.app.gsap.timeline({paused:true})
            this.DOM.burger.classList.toggle('active')
            const active =this.$el.classList.toggle('opened')
            if (active) {
                this.app.emit('disable-scroll')
                this.tl.to(this.DOM.burger,{pointerEvents:"none",duration:0})
                this.tl.fromTo(this.DOM.navMobile,{clipPath:'polygon(0 0, 100% 0, 100% 0, 0 0)'},{clipPath:'polygon(0 0, 100% 0, 100% 100%, 0 100%)',duration:1.5,ease: "0.14, 1.00, 0.34, 1.00"})
                this.tl.fromTo(this.DOM.links,{autoAlpha:0},{autoAlpha:1,duration:0.5,ease: "0.14, 1.00, 0.34, 1.00"},"-=1.5")
                this.tl.fromTo(this.DOM.links,{x:-20,autoAlpha:0},{x:0,autoAlpha:1,duration:1.2,ease: "0.14, 1.00, 0.34, 1.00"},"-=1.5")
                this.tl.to(this.DOM.burger,{pointerEvents:"all",duration:0})
            }else{

                this.tl.to(this.DOM.burger,{pointerEvents:"none",duration:0})
                this.tl.fromTo(this.DOM.links,{x:0},{x:15,duration:1,ease: "0.14, 1.00, 0.34, 1.00"})
                this.tl.fromTo(this.DOM.links,{autoAlpha:1},{autoAlpha:0,duration:0.5,ease: "0.14, 1.00, 0.34, 1.00"},"-=1")
                this.tl.fromTo(this.DOM.navMobile,{clipPath:'inset(0 0 0)'},{clipPath:'inset(0 0 100%)',duration:1,ease: "0.14, 1.00, 0.34, 1.00"},"-=1")
                this.tl.to(this.DOM.burger,{pointerEvents:"all",duration:0})
                this.app.emit('enable-scroll')
            }
            this.tl.play();
        })
        this.DOM.links.forEach(link => {
            link.addEventListener('mouseenter',()=>{
                this.app.emit("add-bg-navbar")
            })
            link.addEventListener('mouseleave',()=>{
                if (this.app.$scroll.scroll.scroll.instance.scroll.y < 50) {
                    this.app.emit("remove-bg-navbar") 
                }
            })
        });
    }
    destroy() { }
}
