import LocomotiveScroll from 'locomotive-scroll';
import AbstractComponent from "./abstract-component";
import lcsCallbacks from './lcs-callbacks';
import lcsProgress from './lcs-progress';
export default class LCS extends AbstractComponent {
	constructor(el) {
		super(el);
		this.init();

	}
	init() {
		this.createScroll();
	}
    createScroll() {
        this.scroll = new LocomotiveScroll({
            el: document.querySelector('.site-container[data-scroll-container]'),
            smooth: true,
            getSpeed: true,
            getDirection: true,
            gestureDirection: 'vertical',
            direction: 'vertical',
            tablet:{
                breakpoint:1189.98,
                smooth: false
            },
            smartphone: {
                smooth: false
            }
        });

        this.scroll.on('call', (value, way, obj) => {
            let calls = (typeof value === 'string') ? [value] : value;
            this.scrollAnimations(calls, way, obj);
        });

        this.scroll.on('scroll', (args) => {
            this.onScroll(args);
        });
        this.scroll.on('resize', (args) => {
            this.update();
        });
    }
	scrollAnimations(calls, way, obj) {
        calls.forEach(call => {
            if (lcsCallbacks[call]) {
                lcsCallbacks[call](way, obj);
            }
        });
	}
    onScroll(args) {
        if (args.direction == 'down' && args.scroll.y > window.innerHeight * 0.2) {
            if (this.app.navVisible) {
                this.app.emit("hide-navbar");
                this.app.navVisible = false
            }

        } else {
            if (args.scroll.y > 0 && !this.app.navVisible) {
                this.app.emit("show-navbar");
                this.app.navVisible = true 
            }
        }
        if (args.scroll.y > window.innerHeight * 0.2) {
            this.app.emit("add-bg-navbar")  
        }else if (args.scroll.y > 0){
            this.app.emit("remove-bg-navbar")
        }
        if (args.scroll.y > args.limit.y - 50) {
            this.app.emit("hide-reservation-ribbon")
        } 
        else if (args.scroll.y > window.innerHeight * 0.5){

            this.app.emit("show-reservation-ribbon")  
        }
        else{
            this.app.emit("hide-reservation-ribbon")
        }
        for (const [key, data] of Object.entries(args.currentElements)) {
            if (lcsProgress[key]) {
                lcsProgress[key](data);
            } else {
                let attr = data.el.getAttribute('data-scroll-progress');
                if (attr && lcsProgress[attr]){
                    lcsProgress[attr](data);
                }
            }
        }
    }
    
    disable() {
        this.scroll.stop();
    }
    enable() {
        this.scroll.start();
    }
    scrollTo(elt, offset = 0) {
        this.scroll.scrollTo(elt, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00], offset: offset });
    }
    scrollTop (force = false) {
        if (force) {
            this.scroll.scrollTo(0, { duration: 0, disableLerp: true });
        } else {
            this.scroll.scrollTo(0, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00] });
        }
    }
    update() {
        this.scroll.update();
    }
	destroy() {}
}
