import AbstractComponent from "./abstract-component";
import gsap from "gsap";


export default class Form extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.tl = this.app.gsap.timeline({paused:true})
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM.Form = this.$el.querySelector('.wpcf7')
        this.DOM.FormWrapper = this.$el.querySelector('.form-wrapper')
        this.DOM.FormResponse = this.$el.querySelector('.wpcf7-response-output')

    }

    initEvents() {
        this.tl.to(this.DOM.FormWrapper,{autoAlpha:0,duration:0.5})
        this.tl.to(this.DOM.FormWrapper,{height:0,onComplete:()=>{
            this.DOM.FormWrapper.after(this.DOM.FormResponse)
            this.app.gsap.set(this.DOM.FormResponse,{autoAlpha:0,x:-10})
            this.app.emit('scrollUpdate')
            this.app.gsap.to(this.DOM.FormResponse,{autoAlpha:1,x:0})
        }})
        this.DOM.Form.addEventListener('wpcf7mailsent',()=>{
            this.tl.play();
        })
    }

    destroy() {
    }
}
