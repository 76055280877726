import AbstractComponent from "./abstract-component";

export default class Dropdowns extends AbstractComponent {

    constructor(el) {
        super(el)
        this.selector = '[data-dropdown-toggle]'
        this.DOM = {};
        this.toggleFunction = this.toggle.bind(this)
        this.init();
    }
    init() {
        this.initDomElements();
        this.iniEvents();
    }
    initDomElements() {
        this.dropdowns = document.querySelectorAll(this.selector)
    }
    toggle(e) {
        e.currentTarget.toggleAttribute('opened')
        if (!e.currentTarget.querySelector('[data-dropdown-content]').style.maxHeight) {
            e.currentTarget.querySelector('[data-dropdown-content]').style.maxHeight = `${e.currentTarget.querySelector('[data-dropdown-content]').scrollHeight}px`
        } else {
            e.currentTarget.querySelector('[data-dropdown-content]').style.maxHeight = ""
        }
        setTimeout(() => {
            this.app.$scroll.update();
        }, 1000);
    }
    setEvent(dropdown) {
        dropdown.addEventListener('click', this.toggleFunction)
    }
    removeEvent(dropdown) {
       dropdown.removeEventListener('click', this.toggleFunction)
    }
    iniEvents() {
        this.dropdowns.forEach(dropdown => {
            this.removeEvent(dropdown)
            if (dropdown.getAttribute('data-dropdown-screen') == "mobile") {
                if (window.matchMedia('(max-width:1189.98px)').matches) {
                    this.setEvent(dropdown)
                }
            } else {
                this.setEvent(dropdown)
            }
        })
    }
    destroy() { }
}
