import AbstractComponent from "./abstract-component";
  export default class Incrementer extends AbstractComponent {

    constructor(el) {
        super(el);
        this.value = 1
        this.max = 5;
        this.DOM = {};
        this.initDomElements()
        this.value = this.DOM.input.value
        this.initEvents();
    }

    initDomElements(){
        this.DOM.minusBtn = this.$el.querySelector('.minus')
        this.DOM.plusBtn = this.$el.querySelector('.plus')
        this.DOM.input = this.$el.querySelector('input')
        this.max = this.DOM.input.getAttribute('max')
    }

    initEvents(){
        this.updateState(this.DOM.input.value);
        this.DOM.minusBtn.addEventListener('click',()=>{
            if (this.value > 1) {
                this.value--
                this.DOM.input.value = this.value
                this.DOM.input.dispatchEvent(new Event('change'));
            }
            this.updateState(this.value)
        })
        this.DOM.plusBtn.addEventListener('click',()=>{
            if (this.value < this.max) {
                this.value++
                this.DOM.input.value = this.value
                this.DOM.input.dispatchEvent(new Event('change'));
            }
            this.updateState(this.value)
        })
    }
    updateState(value){
        if (value == 0) {
            this.DOM.plusBtn.classList.add('disabled')
            this.DOM.minusBtn.classList.add('disabled')
        }else if (value == this.max) {
            this.DOM.minusBtn.classList.remove('disabled')
            this.DOM.plusBtn.classList.add('disabled')
        }else if (value == 1) {
            this.DOM.plusBtn.classList.remove('disabled')
            this.DOM.minusBtn.classList.add('disabled')
        }
         else{
            this.DOM.plusBtn.classList.remove('disabled')
            this.DOM.minusBtn.classList.remove('disabled')
        }
    }
    destroy() { }
}
