import AbstractComponent from "./abstract-component";
  export default class BookingRibbon extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initEvents();
    }


    initEvents(){
        this.app.on('show-reservation-ribbon',()=>{
            this.$el.classList.add('show')

        })
        this.app.on('hide-reservation-ribbon',()=>{
            this.$el.classList.remove('show')
        })
    }
    destroy() { }
}
