import Swiper from "swiper";
import {EffectFade,Navigation,FreeMode,EffectCoverflow,A11y } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, EffectFade,Navigation,EffectCoverflow,A11y]);
  export default class ImageSlider extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        if(window.matchMedia('(max-width:1189.98px)').matches){
            this.initSlider();
          }
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
    }
    initSlider(){
        this.sliderObject = new Swiper(this.$el, {
            allowTouchMove:true,
            slidesPerView:1.3,
            loop:true,
            spaceBetween: -10,
            centeredSlides:true,
            enabled:true,
            a11y:{
                enabled:true
            },
            effect:'coverflow',
            coverflowEffect: {
                scale:0.7,
                rotate:0,
            },
            breakpoints:{
                '1189.98': {
                    enabled:false,
                },
            }

          });
    }
    destroy() { }
}
