export default {
    'site-loader': require( './site-loader.js' ),
    'header-home': require( './header-home.js' ),
    'header-scroller': require( './header-scroller.js' ),
    'push-events': require( './push-events.js' ),
    'image-slider': require( './image-slider.js' ),
    'slider-double': require( './slider-double.js' ),
    'video': require( './video.js' ),
    'text-image': require( './text-image.js' ),
    'form': require( './form.js' ),
    'exhibition-list':require( './exhibition-list.js' ),
    'event-list':require( './event-list.js' ),
    'booking-ribbon':require( './booking-ribbon.js' ),
    'booking-popin':require( './booking-popin.js' ),
    'incrementer':require( './incrementer.js' ),
    'booking-form':require( './booking-form.js' ),
    'words-slider':require( './words-slider.js' ),
}
