import AbstractComponent from "./abstract-component";

export default class SiteLoader extends AbstractComponent {


    constructor(el) {
        super(el);

        this.DOM = {};
        this.count = 0
        this.is_mobile = window.matchMedia('(max-width: 576px)').matches
        this.tl = null;
        this.full_loader = true
        this.initDomElements();
        this.initEvents();

    }

    initDomElements() {
        this.DOM.lines = this.$el.querySelectorAll('.line')
        this.DOM.logos = this.$el.querySelectorAll(`[data-index${this.is_mobile ? '-mobile':'' }] .c-logo`)
    }
    completeLoopStep(){
        const logos = this.$el.querySelectorAll(`[data-index${this.is_mobile ? '-mobile':'' }="${this.count}"] .c-logo`)
        if (logos.length) {
            this.app.gsap.to(logos, {
                autoAlpha: 1, scale:1,duration: 0,
                onComplete:()=>{
                    this.count++
                }
            })
        }
    }
    hideLoader(){
        const lastLoader = parseInt(localStorage.getItem('cdb-loader'), 10),
        nowDate = Date.now();
        /* Every hour */
        if ((lastLoader && nowDate < lastLoader + 1000 * 60 * 60)) {
            this.full_loader = false 
		}else{
            this.full_loader = true 
        }
        this.tl = this.app.gsap.timeline({onComplete:()=>{
            this.$el.classList.add('finished')
            localStorage.setItem('cdb-loader', Date.now());
        }})
        if (this.full_loader) {
            this.count = 0
            this.completeLoopStep()
            this.tl.to(this.DOM.logos, {
                keyframes:[
                    {rotate:90, duration:0.6,delay:0.5,ease: "0.14, 1.00, 0.34, 1.00",},
                    {rotate:180, duration:0.6,delay:0.5,ease: "0.14, 1.00, 0.34, 1.00",onStart:this.completeLoopStep.bind(this)},
                    {rotate:270, duration:0.6,delay:0.5,ease: "0.14, 1.00, 0.34, 1.00",onStart:this.completeLoopStep.bind(this)},
                    {rotate:360, duration:0.6,delay:0.5,ease: "0.14, 1.00, 0.34, 1.00",onStart:this.completeLoopStep.bind(this)},
                ],
            })
            this.tl.to(this.DOM.logos, {
                scale: 0, duration: 0.8, stagger: {
                    each: 0.05,
                    amount: 1,
                    grid:"auto",
                    from: "random"
                },
                ease: "0.14, 1.00, 0.34, 1.00"
            },"-=0.2")
            this.tl.to(this.DOM.lines, {y: '-100%', duration: 1, stagger: 0.1, ease: "0.90, 0.00, 0.10, 1.00", },"-=1.2")
        }else{
            this.tl.to(this.DOM.lines, {y: '-100%', duration: 1, stagger: 0.1, ease: "0.90, 0.00, 0.10, 1.00", },"+=1.2")
        }
    }
    showLoader(resolve){
        this.$el.classList.remove('finished')
        this.app.gsap.fromTo(this.DOM.lines,{y: '100%'},{y: '0%',duration: 1, stagger: 0.1, ease: "0.90, 0.00, 0.10, 1.00",onComplete:()=>{
            resolve();
        }})
        
    }
    initEvents() {
        this.hideLoader();
        // this.app.on('hide-loader',()=>{
        //     this.hideLoader();
        // })
        this.app.on('show-loader',(resolve)=>{
            this.showLoader(resolve);
        })
    }
    destroy() { }
}
