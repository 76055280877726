import AbstractComponent from "./abstract-component";

export default class BookingForm extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements()
        this.initEvents();
    }

    initDomElements() {

        this.DOM.form = this.$el.querySelector('.form')
        this.DOM.result = this.$el.querySelector('.result')
        this.DOM.response = this.$el.querySelector('.wpcf7-response-output')
        this.DOM.seatsVisibleInput = this.$el.querySelector('[data-component="incrementer"] input[type="number"]')
        this.DOM.seatsHiddenInput = this.$el.querySelector('input[name="seats"]')
        this.DOM.eventInput = this.$el.querySelector('input[name="event"]')
        this.DOM.eventInput.value = this.$el.getAttribute('data-event-id')
        this.DOM.eventInput.dispatchEvent(new Event('change'));
        this.DOM.seatsHiddenInput.value = this.DOM.seatsVisibleInput.value <= this.DOM.seatsVisibleInput.getAttribute('max') && this.DOM.seatsVisibleInput.value >= 0 ? this.DOM.seatsVisibleInput.value : 1
        this.DOM.seatsHiddenInput.dispatchEvent(new Event('change'));
        setTimeout(() => {
            this.app.$scroll.update();
        }, 500);
    }
    getErrorMessage(fieldName,errorData) {
        const error = errorData.find((error) => error.field === fieldName);
        return error ? error.message : null;
    }
    initEvents() {
        this.DOM.seatsVisibleInput.addEventListener('change',()=>{
            this.DOM.seatsHiddenInput.value = this.DOM.seatsVisibleInput.value
            this.DOM.seatsHiddenInput.dispatchEvent(new Event('change'));
            setTimeout(() => {
                this.app.$scroll.update();
            }, 500);
        })
        document.addEventListener('wpcf7mailsent',()=>{

            this.app.gsap.to(this.$el.querySelector('.row'),{autoAlpha:0.8})
            setTimeout(() => {
                this.app.gsap.fromTo(this.$el.querySelector('.row'),{height:this.$el.querySelector('.row').getBoundingClientRect().height,autoAlpha:0.8},{height:0,autoAlpha:0,onComplete:()=>{
                    this.app.$scroll.update()
                    this.app.$scroll.scrollTop()
                    this.app.gsap.fromTo(this.DOM.result,{height:0,autoAlpha:0},{height:this.DOM.result.scrollHeight,autoAlpha:1,onComplete:()=>{
                        this.app.gsap.set(this.DOM.result,{height:'auto'})
                        this.app.$scroll.update()
                        this.app.$scroll.scrollTop()
                    }})
                }})
            }, 1000);

        })
        document.addEventListener('wpcf7invalid', (event) =>{
          
            const seatsErrorMessage = this.getErrorMessage("seats",event.detail.apiResponse.invalid_fields);
            const eventErrorMessage = this.getErrorMessage("event",event.detail.apiResponse.invalid_fields);
            const emailErrorMessage = this.getErrorMessage("user_1_email",event.detail.apiResponse.invalid_fields);
            setTimeout(() => {
                if (seatsErrorMessage) {
                    this.DOM.response.innerText = seatsErrorMessage
                }
                if (eventErrorMessage) {
                    this.DOM.response.innerText = eventErrorMessage
                }
                if (emailErrorMessage) {
                    this.DOM.response.innerText = emailErrorMessage
                }
            }, 100);
        }, false);
        
    }
    destroy() { }
}
