import AbstractComponent from "./abstract-component";
  export default class ExhibitionList extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.left = this.$el.querySelector('.left')
        this.DOM.right =this.$el.querySelector('.right')
    }
    initEvents(){
        if(window.matchMedia('(max-width:1189.98px)').matches){
            const leftChildren = Array.from(this.DOM.left.children).filter(child => child.tagName.toLowerCase() === 'article');
            const rightChildren = Array.from(this.DOM.right.children).filter(child => child.tagName.toLowerCase() === 'article');
            const title = this.DOM.left.children[0]

            let childs = []
            for (let i = 0; i < Math.max(leftChildren.length, rightChildren.length); i++) {
                if (leftChildren[i]) {
                    childs.push(leftChildren[i].cloneNode(true));
                }
                if (rightChildren[i]) {
                    childs.push(rightChildren[i].cloneNode(true));
                }
            }
            this.DOM.left.innerHTML = '';
            this.DOM.left.append(title,...childs)
            this.DOM.right.innerHTML = '';
        }
    }
    destroy() { }
}
