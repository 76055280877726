import Swiper from "swiper";
import { Autoplay,EffectFade,Navigation,FreeMode } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, EffectFade,Navigation]);
  export default class TextImage extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
        this.DOM.imageLeft = this.$el.querySelector('.image-one')
        this.DOM.imageRight =this.$el.querySelector('.image-two')
    }
    initEvents(){
        if(window.matchMedia('(max-width:1189.98px)').matches && this.DOM.imageRight){
            let img1Src = this.DOM.imageLeft.getAttribute('src')
            let img1Alt = this.DOM.imageLeft.getAttribute('alt')
            let img2Src = this.DOM.imageRight.getAttribute('src')
            let img2Alt = this.DOM.imageRight.getAttribute('alt')

            this.DOM.imageLeft.setAttribute('src',img2Src)
            this.DOM.imageLeft.setAttribute('alt',img2Alt)
            this.DOM.imageRight.setAttribute('src',img1Src)
            this.DOM.imageRight.setAttribute('alt',img1Alt)
          }
    }
    destroy() { }
}
