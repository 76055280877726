import Swiper from "swiper";
import {Autoplay, FreeMode} from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode,Autoplay]);
  export default class WordsSlider extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initSlider();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
    }
    initSlider(){
        this.sliderObject = new Swiper(this.DOM.slider, {
            allowTouchMove:false,
            slidesPerView:'auto',
            loop:true,
            speed:10000,
            autoplay: {
                enabled: true,
                delay: 0,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
            },
            loopAddBlankSlides:true,
            centeredSlides:false,
            enabled:true,
            freeMode:true,
          });
    }
    destroy() { }
}
