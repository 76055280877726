import request from "../vendors/request";
import AbstractComponent from "./abstract-component";
import { gsap } from 'gsap';

export default class EventList extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.request = null;
        this.isMobile = false;
        this.action = "filter_events_ajax";
        this.params = {
            period: null,
            event_category: null,
            public: null,
            paged: 1
        };
        this.lastFetch = null;
        this.initDomElements();
        this.initEvents();
        this.checkFilterValues();
    }

    initDomElements() {
        this.DOM.left = this.$el.querySelector('.left');
        this.DOM.center = this.$el.querySelector('.center');
        this.DOM.right = this.$el.querySelector('.right');
        this.DOM.noPostMsg = this.$el.querySelector('.no-post');
        this.DOM.moreBtn = this.$el.querySelector('.moreBtn');
        this.DOM.resetBtn = this.$el.querySelector('.reset');
        this.DOM.selects = this.$el.querySelectorAll('select');

        if (window.matchMedia('(max-width: 879.98px)').matches) {
            this.isMobile = true;

            const leftChildren = Array.from(this.DOM.left.children).filter(child => child.tagName.toLowerCase() === 'article');
            const centerChildren = Array.from(this.DOM.center.children).filter(child => child.tagName.toLowerCase() === 'article');
            const rightChildren = Array.from(this.DOM.right.children).filter(child => child.tagName.toLowerCase() === 'article');

            let childs = [];
            for (let i = 0; i < Math.max(leftChildren.length, rightChildren.length); i++) {
                if (leftChildren[i]) {
                    childs.push(leftChildren[i].cloneNode(true));
                }
                if (centerChildren[i]) {
                    childs.push(centerChildren[i].cloneNode(true));
                }
                if (rightChildren[i]) {
                    childs.push(rightChildren[i].cloneNode(true));
                }
            }
            this.DOM.left.innerHTML = '';
            this.DOM.left.append(...childs);
            this.DOM.right.innerHTML = '';
            this.DOM.center.innerHTML = '';
            setTimeout(() => {
                this.app.initReservationOpeners();
            }, 200);
        }
    }

    initEvents() {
        this.DOM.moreBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.getNextPage();
        });

        this.DOM.resetBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.resetFilters();
        });

        this.DOM.selects.forEach(select => {
            select.addEventListener('change', () => {
                const filterName = select.getAttribute('name');
                const filterValue = select.value;
                this.lastFetch = 'filter'
                this.params[filterName] = filterValue;
                if (Object.keys(this.params).every(key => !this.params[key])) {
                    
                    this.app.gsap.fromTo(this.DOM.resetBtn, { autoAlpha: 1, x: 0 }, { autoAlpha: 0, x: -5 ,onComplete:()=>{
                        this.app.gsap.set(this.DOM.resetBtn, { display: 'none' })
                    }})
                } else {
                    this.app.gsap.set(this.DOM.resetBtn, { display: 'flex' })
                    this.app.gsap.fromTo(this.DOM.resetBtn, { autoAlpha: 0, x: 5 }, {
                        autoAlpha: 1, x: 0})
                }
                this.params.paged = 1;
                this.fetchEvents();
            });
        });
    }
    checkFilterValues() {
        // Vérifier si l'un des champs <select> a une valeur sélectionnée
        const selects = Array.from(this.DOM.selects);
        const hasFilterValue = selects.some(select => !!select.value);
    
        // Afficher ou masquer le bouton de réinitialisation en fonction de la présence de valeurs dans les champs <select>
        if (hasFilterValue) {
            this.app.gsap.set(this.DOM.resetBtn, { display: 'flex', autoAlpha: 1 });
        } else {
            this.app.gsap.set(this.DOM.resetBtn, { display: 'none', autoAlpha: 0 });
        }
    }
    updateUrlParams() {
        const urlParams = new URLSearchParams();
    
        // Ajouter les paramètres d'URL uniquement s'ils ne sont pas null ou vides
        if (this.params.period) {
            urlParams.set('period', this.params.period);
        }
        if (this.params.event_category) {
            urlParams.set('event_category', this.params.event_category);
        }
        if (this.params.public) {
            urlParams.set('public', this.params.public);
        }
        urlParams.set('paged', this.params.paged);
    
        // Mettre à jour l'URL sans recharger la page
        const newUrl = `${window.location.pathname.replace(/\/page\/\d+\//, '/')}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
        history.pushState({}, '', newUrl);
    }
    getNextPage() {
        this.params.paged++;
        this.lastFetch = 'next'
        this.fetchEvents();
    }

    fetchEvents() {
        this.updateUrlParams();

        this.DOM.moreBtn.classList.add('is-loading')
        this.request = request.AJAX({
            'url': window.JWP.ajax_url + "?action=" + this.action,
            'data': this.params,
            'success': this.onSuccess.bind(this)
        });
    }

    onSuccess(response) {
        const data = response.data;
        if (this.lastFetch == 'filter') {
            this.clearColumns();
        }
        if (data.events) {
            if (this.isMobile) {
                // On mobile, only append events to the left column
                data.events.forEach(event => {
                    this.DOM.left.innerHTML += event.html;
                });
            } else {
                // For desktop, distribute events among columns based on shortest length
                const columns = [this.DOM.left, this.DOM.center, this.DOM.right];
                data.events.forEach(event => {
                    const shortestColumnIndex = this.getShortestColumnIndex(columns);
                    columns[shortestColumnIndex].innerHTML += event.html;
                });
            }
            this.DOM.moreBtn.classList.remove('is-loading')
            setTimeout(() => {
                this.app.$scroll.update();
                this.app.initReservationOpeners();
            }, 200);
        }
        // Toggle visibility of "More" button based on has_more flag
        gsap.set(this.DOM.noPostMsg, { display: !data.events.length && this.lastFetch == "filter" ? 'block' : 'none' });
        gsap.set(this.DOM.moreBtn, { display: data.has_more ? 'flex' : 'none' });
    }


    resetFilters() {
        this.params.period = null;
        this.params.event_category = null;
        this.params.public = null;
        this.params.paged = 1; // Réinitialiser la page à 1 après la réinitialisation des filtres
        this.clearColumns(); // Réinitialiser les colonnes lorsque les filtres sont réinitialisés
        this.fetchEvents(); // Recharger les événements après la réinitialisation des filtres
        this.DOM.selects.forEach(select => {
            select.value = ''; // Remettre la valeur du select à vide
        });
        this.app.gsap.fromTo(this.DOM.resetBtn, { autoAlpha: 1, x: 0 }, {
            autoAlpha: 0, x: -5, onComplete: () => {
                this.app.gsap.set(this.DOM.resetBtn, { display: 'none' })
            }
        })
    }

    clearColumns() {
        [this.DOM.left, this.DOM.center, this.DOM.right].forEach(column => {
            column.innerHTML = '';
        });
    }

    getShortestColumnIndex(columns) {
        let shortestIndex = 0;
        let shortestLength = columns[0].children.length;

        for (let i = 1; i < columns.length; i++) {
            if (columns[i].children.length < shortestLength) {
                shortestLength = columns[i].children.length;
                shortestIndex = i;
            }
        }

        return shortestIndex;
    }

    destroy() {
        // Nettoyer les écouteurs d'événements ou d'autres ressources
    }
}
