import Swiper from "swiper";
import { A11y, Autoplay, EffectFade, Navigation } from 'swiper/modules';
import AbstractComponent from "./abstract-component";
import gsap from "gsap";

Swiper.use([Autoplay, EffectFade, Navigation,A11y]);
export default class SiteMenu extends AbstractComponent {

  constructor(el) {
    super(el);
    this.DOM = {};
    this.config = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1000,
      a11y:{
        enabled:true
      },
      navigation: {
        nextEl: null,
        prevEl: null,
      },
      effect: "fade",
      loop: true,
      centeredSlides: true,
      fadeEffect: { crossFade: true },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    }
    this.currentIndex = 0;
    this.initDomElements();
    this.initSlider();

  }

  initDomElements() {
    this.DOM = {}
    this.DOM.slider = this.$el.querySelector('.swiper')
    this.DOM.counter = this.$el.querySelector('.counter')
    this.DOM.sliderPreviews = this.$el.querySelectorAll('.c-header-home__slider-preview__cover')
    this.DOM.sliderArrowNext = this.$el.querySelector('.c-header-home__slider-preview__arrow.next')
    this.DOM.sliderArrowPrev = this.$el.querySelector('.c-header-home__slider-preview__arrow.prev')
    this.DOM.headerContents = this.$el.querySelectorAll('.c-header-home__content')
    if (window.matchMedia('(max-width:1189.98px)').matches) {
      this.DOM.titlesSlider = [null]
    } else {
      this.DOM.titlesSlider = this.$el.querySelectorAll('.c-header-home__slider-preview__title')
    }

    this.previews = this.$el.querySelectorAll('.c-header-home__slider-preview__cover')
  }
  initSlider() {

    if (this.previews.length > 1) {
      if (this.DOM.headerContents.length) {
        this.app.gsap.set([this.DOM.headerContents, this.DOM.titlesSlider], { display: "none" })
        this.app.gsap.set([this.DOM.headerContents[0], this.DOM.titlesSlider[0]], { display: "flex" })
      }
      this.config.navigation.nextEl = this.DOM.sliderArrowNext
      this.config.navigation.prevEl = this.DOM.sliderArrowPrev
      this.sliderObject = new Swiper(this.DOM.slider, this.config);
      this.DOM.sliderPreviews[0].classList.add('active')
      this.sliderObject.on('realIndexChange', (e) => {
        this.$el.querySelector('.c-header-home__slider-preview__cover.active').classList.remove('active')
        this.DOM.sliderPreviews[e.realIndex].classList.add('active')
        this.DOM.counter.innerHTML = `0${e.realIndex + 1}  —  0${this.DOM.sliderPreviews.length}`
        gsap.fromTo(this.DOM.headerContents[this.currentIndex], { y: 0, autoAlpha: 1 }, { y: -10, autoAlpha: 0, duration: 1, ease: "0.14, 1.00, 0.34, 1.00" })
        gsap.set(this.DOM.headerContents[this.currentIndex], { display: "none" })
        this.currentIndex = e.realIndex
        gsap.set(this.DOM.headerContents[e.realIndex], { display: "flex" })
        this.app.$scroll.update()
        gsap.fromTo(this.DOM.headerContents[e.realIndex], { y: 10, autoAlpha: 0 }, { y: 0, autoAlpha: 1, duration: 1, ease: "0.14, 1.00, 0.34, 1.00" })
        this.DOM.sliderArrowPrev.classList.remove('disabled')
      });
      this.DOM.sliderPreviews.forEach((preview, index) => {
        preview.addEventListener('click', () => {
          this.sliderObject.slideToLoop(index)
        })
      });
    }
  }
  destroy() { }
}
