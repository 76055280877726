export default {
    applyCoefficient(value, coefficient) {
        console.log(value);
        // Assurez-vous que la valeur est comprise entre 0 et 1
        value = Math.max(0, Math.min(1, value));
    
        // Appliquer le coefficient de manière exponentielle
        var result = Math.pow(value, coefficient);
    
        return result;
    },
    getPreviousSibling (elem, selector) {

        // Get the next sibling element
        let sibling = elem.previousElementSibling;

        // If there's no selector, return the first sibling
        if (!selector) return sibling;

        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.previousElementSibling;
        }

    },

    getNextSibling (elem, selector) {

        // Get the next sibling element
        let sibling = elem.nextElementSibling;

        // If there's no selector, return the first sibling
        if (!selector) return sibling;

        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }
    },
    setNewAttrs (html) {
        const classRegex = /data-body-class="(.[^"]+)"/g;
        const controllerRegex = /data-controller="(.[^"]+)"/g;
        const newController = controllerRegex.exec(html);
        const newBodyClass = classRegex.exec(html);
        if (newBodyClass) {
            document.body.setAttribute('data-controller',newController[1])
            document.body.className = newBodyClass[1];
        }
    },
    deviceType () {
        let ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    },

    debounce (func, wait, immediate) {
        var timeout;
        wait = wait ? wait : 1000 / 60;
    
        return () => {
            var context = this, args = arguments;
            var later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },
}